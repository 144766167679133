/* *, *:before, *:after  fixed
{ box-sizing: border-box; }

html { font-size: 18px; } */

/* body {
  color: #e0e4cc;
  font-size: 1.2em;
  line-height: 1.6;
  background: #fa6900;
  overflow-x: hidden;
} */

label .menu {
  position: fixed;
  right: -100px;
  top: -100px;
  z-index: 100;
  width: 200px;
  height: 200px;
  background: var(--purple);
  border-radius: 50% 50% 50% 50%;
  -webkit-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  box-shadow: 0 0 0 0 var(--purple), 0 0 0 0 var(--purple);
  cursor: pointer;
  
}

label .hamburger {
  position: absolute;
  top: 135px;
  left: 50px;
  width: 30px;
  height: 2px;
  background: whitesmoke;
  display: block;
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
}

label .hamburger:after, label .hamburger:before {
  -webkit-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background: whitesmoke;
}

label .hamburger:before { top: -10px; }

label .hamburger:after { bottom: -10px; }

label input { display: none; }

label input:checked + .menu {
  box-shadow: 0 0 0 100vw var(--purple), 0 0 0 100vh var(--purple);
  border-radius: 0;
  
}

label input:checked + .menu .hamburger {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  
}

label input:checked + .menu .hamburger:after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  bottom: 0;
  
}

label input:checked + .menu .hamburger:before {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  top: 0;
  
}

label input:checked + .menu + ul { opacity: 1; }

label ul {
    list-style: none;
  z-index: 200;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0;
  -webkit-transition: .25s 0s ease-in-out;
  transition: .25s 0s ease-in-out;
}

label a {
  margin-bottom: 1em;
  display: block;
  color: whitesmoke;
  text-decoration: none;
  
}

.navbar h5 {

}

.navbar h5:after {
	content: '';
    position: absolute;
    left: 0;
    display: inline-block;
    height: 1em;
    width: 100%;
    border-bottom: 1px solid;
    margin-top: 10px;
    opacity: 0;
	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	transition: opacity 0.35s, transform 0.35s;
	-webkit-transform: scale(0,1);
	transform: scale(0,1);
}

.navbar h5:hover:after {
    opacity: 1;
	-webkit-transform: scale(1);
	transform: scale(1);
}

@media only screen and (max-width: 850px) {
    label .menu  {
        right: -75px;
        top: -75px;
        z-index: 100;
        width: 150px;
        height: 150px;
    }

    label .hamburger {
        top: 105px;
        left: 33px;
    }
  }

/* .navbar h5:hover {
    text-decoration: underline;
} */

/* h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 80%;
  text-align: center;
} */