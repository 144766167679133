@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&family=Poppins:wght@400;700&display=swap');

* {
    margin: 0;
    padding: 0;
}

html {
    scroll-behavior: smooth;
}

body {
    --blue: #16213E;
    --navy: #0F3460;
    --purple: #533483;
    --red: #E94560;
    --black: #212121;
    --lightblue: #23FED6;
}

.App {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}

.container {
    max-width: 1400px; 
    min-width: 1200px;
}

h1, h2, h3, h4, h5 {
    /* font-family: 'Space Grotesk', sans-serif; */
}

h1 {
    font-size: 120px;
    font-family: 'Poppins', sans-serif;
}
h2 {
    font-size: 90px;
    font-family: 'Poppins', sans-serif;
}
h3 {
    font-size: 40px;
    font-family: 'Poppins', sans-serif;
}
h4 {
    font-size: 55px;
    font-family: 'Poppins', sans-serif;
}

h5 {
    font-size: 24px;
    font-family: 'Poppins', sans-serif;
}

p {
    font-family: 'Open Sans', sans-serif;
}

@media only screen and (max-width: 850px) {
    .container {
        width: 100vw;
        min-width: unset !important;

        padding: 1em;
    }
  }


  @media only screen and (min-width: 700px) and (max-width: 900px) {
    .container {
        padding: 3em;
    }
  }