#contact {
    width: 100vw;
    height: 20vh;
    background: #000000;
    color: whitesmoke;
    display: flex;
    justify-content: center;
    border-top: 1px solid var(--purple)
}

p {
    text-align: left;
    padding: 0.25em;
}

.footer-wrapper {
    display: flex;
    justify-content: space-between;
    align-items:  center;
}

#contact img {
    width: 200px;
}

.company-info {
    display: flex;
}

.address {
    margin-right: 3em; 
}

a {
    color: whitesmoke;
}

.contact p{
    text-decoration: underline;
}

@media only screen and (max-width: 850px) {
    #contact {
        height: 30vh;
        padding: 2em 0;
    }

    .footer-wrapper {
        flex-direction: column-reverse;
        align-items: center;
        padding: 0;
    }

}

@media only screen and (min-width: 700px) and (max-width: 900px) {
    #contact{
        height: 15vh;
    }

    #contact div {
        flex-direction: row;
        justify-content: space-around;
    }
  }